import { render, staticRenderFns } from "./remittance-analytics.vue?vue&type=template&id=a5c2c9e4"
import script from "./remittance-analytics.vue?vue&type=script&lang=js"
export * from "./remittance-analytics.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports